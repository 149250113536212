
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import InsightTypeModel from '@/modules/insights/models/insight-type.model';
import { COLOR_NAME } from '@/modules/common/constants/color-names';
import { INSIGHT_CARD_VALUE } from '@/modules/cluster/constants';

@Component
export default class InsightsClusterCard extends Vue {
// Pure component. Don't inject any logic here. Use only props

    @Prop({ type: Object })
    insightType!: InsightTypeModel;

    @Prop({ type: [Object, String], default: INSIGHT_CARD_VALUE.NA })
    card!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    get isNa() {
        return this.card === INSIGHT_CARD_VALUE.NA;
    }

    get isNoChanges() {
        return this.card === INSIGHT_CARD_VALUE.NOCHANGES;
    }

    get isValidValue() {
        return !this.isNa && !this.isNoChanges;
    }

    get cardTooltip() {
        return this.isValidValue
            ? (this.card as { tooltip: string }).tooltip
            : this.$t(`insights.cluster.tooltip.${this.card}`);
    }

    get cardColor() {
        return this.isValidValue
            ? (this.card as { color: COLOR_NAME }).color
            : undefined;
    }

    get cardItems() {
        return this.isValidValue
            ? (this.card as { items: Item[] }).items
            : [];
    }

    handleCardClick() {
        if (!this.isNa) {
            this.$emit('click', this.insightType);
        }
    }
}
