
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ClusterInsightsModel from '@/modules/cluster/models/cluster-insights.model';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import InsightFiltersService, { InsightFiltersServiceS } from '@/modules/document-filters/insight-filters.service';
import InsightTypeLabel from '@/modules/insights/components/insight-type-label.vue';
import HotelsList from '../../_common/hotels-list/index.vue';
import InsightsClusterHotelData from './insights-cluster-hotel-data.vue';
import ClusterInsightsService, { ClusterInsightsServiceS } from '../../../cluster-insights.service';

@Component({ components: { HotelsList, InsightTypeLabel } })
export default class InsightsClusterList extends Vue {
    @inject(ClusterInsightsServiceS) private clusterInsightsService!: ClusterInsightsService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;
    @inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;

    isFirstLoading = true;
    contentHeight: string | null = null;

    get tableData() {
        if (this.hotels === null || !this.hotels.length) {
            return [];
        }

        return this.hotels.map(h => ({
            table: [
                {
                    value: h.hotelName,
                    onClick: () => this.toHotel(h),
                },
            ],
            component: {
                element: InsightsClusterHotelData,
                props: { hotelData: h },
            },
        }));
    }

    get insightTypes() {
        return this.insightFiltersService.options.insightTypes
            .filter(t => t.isActive)
            .map(t => ({
                name: t.name,
                value: t.value,
            }));
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get hotels() {
        return this.clusterInsightsService.hotels;
    }

    get pagesCount() {
        return this.clusterInsightsService.hotelsCount;
    }

    private async toHotel(hotelData: ClusterInsightsModel) {
        await this.userViewService.goToHotelPage(hotelData.hotelId);
        this.insightFiltersService.updateSettings({ insightTypes: this.insightFiltersService.options.insightTypes });
    }
}
