import { render, staticRenderFns } from "./insights-cluster-header.vue?vue&type=template&id=82251b74&scoped=true&"
import script from "./insights-cluster-header.vue?vue&type=script&lang=ts&"
export * from "./insights-cluster-header.vue?vue&type=script&lang=ts&"
import style0 from "./insights-cluster-header.vue?vue&type=style&index=0&id=82251b74&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82251b74",
  null
  
)

export default component.exports