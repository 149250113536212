
import { Component, Mixins } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import InsightsClusterOtelMixin from '@/modules/open-telemetry/mixins/insights/insights-cluster-otel.mixin';
import ClusterPageMixin from '../mixins/cluster-page.mixin';
import InsightsClusterHeader from '../components/insights/insights-cluster-header.vue';
import InsightsClusterActions from '../components/insights/insights-cluster-actions.vue';
import InsightsClusterList from '../components/insights/insights-cluster-list/index.vue';

@Component({
    components: {
        PageWrapper,
        InsightsClusterHeader,
        InsightsClusterActions,
        InsightsClusterList,
    },
})
export default class InsightsClusterPage extends Mixins(InsightsClusterOtelMixin, ClusterPageMixin) {}
