import { Component, Mixins } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import ClusterOtelMixin from '../common/cluster-otel.mixin';

/**
 * Mixin for insight cluster pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class InsightsClusterOtel extends Mixins(ClusterOtelMixin) {
    @inject(ClusterMarketsServiceS) protected clusterMarketsService!: ClusterMarketsService;

    get payload() {
        const filterToBuild = ['dateRange', 'provider'];
        const settingsUnion = {} as Record<string, any>;

        const filterBy = filterToBuild.reduce((acc, key) => {
            acc[key] = settingsUnion[key];
            return acc;
        }, {} as Record<string, any>);

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(filterBy),
        };
    }
}
